import { i18n } from "~/plugins/i18n";
import { useAppStateStore } from '~/stores/appState'


import Slide_info from '~/pages/v2/realtimeSlides/main.vue'
import Slide_share from '~/pages/v2/realtimeSlides/link.vue'
import Slide_photos from '~/pages/v2/realtimeSlides/photos.vue'
import Slide_howtouse from '~/pages/v2/realtimeSlides/howToUse.vue'
import Slide_faqs from '~/pages/v2/realtimeSlides/faqs.vue'


export const useContentRealtimeSlide = (page: string) => {

  const appStateStore = useAppStateStore();

  const lang = computed(() => i18n.global.locale.value)
  const isThaiLang = computed(() => lang.value === 'th')

  // ------------- Realtime Slides ------------------

  const contentSlideHome = computed(() => {
    return {
      title: isThaiLang.value ? "การเล่นสไลด์แบบเรียลไทม์" : "Realtime Slides",
      menus: [
        {
          name: isThaiLang.value ? "รูปพรีเวดดิ้ง" : "Preweddings",
          icon: "fa-sharp fa-solid fa-images",
          id: "SlidePhotos",
          callback: () => {
            appStateStore.setMenuSelected("SlidePhotos");
          },
        },
        {
          name: isThaiLang.value ? "ข้อมูลการเล่นสไลด์" : "Slide Information",
          icon: "fa-sharp fa-solid fa-presentation-screen",
          id: "SlideInfo",
          callback: () => {
            appStateStore.setMenuSelected("SlideInfo");
          },
        },
        {
          name: isThaiLang.value ? "แชร์" : "Share",
          icon: "fa-sharp fa-solid fa-share-nodes",
          id: "SlideLink",
          callback: () => {
            appStateStore.setMenuSelected("SlideLink");
          },
        },
        
        // {
        //   name: isThaiLang.value ? "ขั้นตอนการใช้งาน" : "How To Use",
        //   icon: "fa-duotone fa-solid fa-book",
        //   id: "SlideHowToUse",
        //   callback: () => {
        //     appStateStore.setMenuSelected("SlideHowToUse");
        //   },
        // },
        // {
        //   name: isThaiLang.value ? "คำถามที่พบบ่อย" : "FAQs",
        //   icon: "fa-solid fa-message-question",
        //   id: "SlideFaqs",
        //   callback: () => {
        //     appStateStore.setMenuSelected("SlideFaqs");
        //   },
        // },
      ],
      components: {
        'SlideInfo': Slide_info,
        'SlideLink': Slide_share,
        'SlidePhotos': Slide_photos,
        'SlideHowToUse': Slide_howtouse,
        'SlideFaqs': Slide_faqs,
      }
    }
  })

  const contentSlideInfo = computed(() => {
    return {
      title: isThaiLang.value ? "ปรับแต่งข้อมูล (Optional)" : "Custom Information (Optional)",
      customHashtag: isThaiLang.value ? "กำหนด Hashtag เอง" : "Custom Hashtag",
      customHashtagEdit: isThaiLang.value ? "กำหนด Hashtag เอง (english only)" : "Custom Hashtag (ภาษาอังกฤษเท่านั้น)",
      subTitle: isThaiLang.value ? "เวลาที่ใช้ในการเล่น Real-time Slides" : "Timer for Play Real-time Slides",
      photo: isThaiLang.value ? "รูปพรีเว็ดดิ้ง" : "Photos",
      wish: isThaiLang.value ? "รูปคำอวยพร" : "Wish",
      unit: isThaiLang.value ? "วินาที" : "seconds",
      photoEdited: isThaiLang.value ? "รูปพรีเว็ดดิ้ง / รูป" : "Photos / 1 photo",
      wishEdited: isThaiLang.value ? "รูปคำอวยพร / รูป" : "wishes / 1 wish",
    }
  })

  const contentSlidePhotos = computed(() => {
    return {
      title: isThaiLang.value ? "" : "",
      quantity: isThaiLang.value ? "จำนวนรูป" : "Photos",
      empty: isThaiLang.value ? "ไม่มีรูปภาพ" : "Empty",
      uploadButton: {
        mobile: isThaiLang.value ? "Upload ผ่านมือถือ" : "Upload with Mobile",
        computer: isThaiLang.value ? "Upload ผ่าน Computer / Desktop" : "Upload with Computer / Desktop ",
      },
      selected: isThaiLang.value ? "เลือก" : "Selected",
      unit: isThaiLang.value ? "รูป" : "Photos"
    }
  })

  const contentSlideShare = computed(() => {
    return {
      title: isThaiLang.value ? "แชร์ข้อมูลงาน" : "Choose data sharing",
      list: [
        {
          id: "link-hotel",
          title:  isThaiLang.value ? "Link Realtime Slides (สำหรับให้เปิดในห้องงานเลี้ยง)" : "Link Realtime Slides (For Hall / Banquet Room)",
          description:  isThaiLang.value ? "กด Copy แล้วส่งให้ทางโรงแรมเพื่อเปิดในห้องงานเลี้ยง ในระหว่างก่อนเริ่มงาน" : "click `Copy Link` button and sent to hotel or organize to play in the hall / banquet room before the event starts",
          label: isThaiLang.value ? "คัดลอกลิ้งค์" : "Copy Link",
          type:"link",
        },
      ]
    }
  })

  const contentSlideHowToUse = computed(() => {
    return {
      title: isThaiLang.value ? 'ขั้นตอนการใช้งาน' : 'How To Use',
      list: [
        {
          title: 'step 1',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 2',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 3',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
        {
          title: 'step 4',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam dolorem nam sapiente laudantium dolores nihil sequi quam architecto eum? Magni, dolorem! Aliquam, id sint. Deserunt quas odit voluptas hic esse!'
        },
      ]
    }
  })

  const contentSlideFaqs = computed(() => {
    return {
      title: isThaiLang.value ? 'คำถามที่พบบ่อย' : 'FAQs',
      list: [
        {
          question: "PhotoWish คืออะไร",
          answer: "PhotoWish คือการเก็บบันทึกคำอวยพรสุดพิเศษจากแขกที่มาในงาน และบ่าวสาวจะได้รับรูปเล่มคำอวยพรด้วยรูป (WishBook) จัดส่งให้ในภายหลัง",
          open: false,
        },
        {
          question: "แขกจะเขียนอวยพรได้อย่างไร",
          answer: "สำหรับแพ็คเกจโปร ทางเรา QR-CODE และ Stand QR-Code(A4) จัดส่งไปให้ล่วงหน้าในวันงานเจ้าบ่าวเจ้าสาวเพียงตั้ง QR-CODE ไว้ที่จุดลงทะเบียน(จุดรับของชำร่วย/ใส่ซอง) แขกแค่สแกนและอวยพร แทนการเขียนด้วยสมุด (ไม่ต้องโหลด App หรือติดตั้งโปรแกรม ใดๆทั้งสิ้น)",
          open: false,
        },
        {
          question: "แขกเลือกรูปที่จะอวยพรได้จากไหน",
          answer: "หลังจาก Scan แล้วจะเข้าสู่ระบบ, เลือกรูปจาก Gallery ในมือถือของตัวเองได้เลย และเขียนคำอวยพรได้ทันที (ไม่ต้องโหลด App หรือติดตั้งโปรแกรม ใดๆทั้งสิ้น) ",
          open: false,
        },
        {
          question: "การต่อภาพ Slides และ รูปคำอวยพรแบบRealtime จะทำอย่างไร",
          answer: "เจ้าบ่าวเจ้าสาวสามารถแชร์ลิ้งค์ ให้กับทางโรมแรมเพื่อนำไปเปิดบนจอในห้องงานเลี้ยงได้เลย (การเปิด Slide Online เหมือนเปิดลิ้งค์ Youtube ทั่วไป) ",
          open: false,
        },
        {
          question: "ฉันสามารถแก้ไขและจัดเรียงรูปถ่ายในโฟโต้บุ๊คได้ไหม",
          answer: "หลังจากจบงาน ทางเราผลิต WishBook และจัดส่งให้ตามวันที่เจ้าบ่าวเจ้าสาวกำหนดไว้ในระบบ, กรณีส่ง messenger เฉพาะกรุงเทพมหานครและปริมณฑลจะได้รับหนังสือภายในวันนั้น (มีค่าจัดส่งเพิ่มเติม), กรณีส่ง ไปรษณีย์ จะได้รับภายใน 2-3 วัน(ขึ้นกับบริษัทขนส่ง) ",
          open: false,
        },
        {
          question: "ในวันแต่งจะมีเจ้าหน้าที่มาที่หน้างานหรือไม่",
          answer: "PhotoWish เป็นระบบออนไลน์ จะไม่มีเจ้าหน้าที่ไปอยู่ที่งานแต่ง  (แต่จะมีเจ้าหน้าที่ IT support ช่วยเหลือทางออนไลน์ในกรณีติดปัญหาการใช้งาน โทร: 063-048-4510)",
          open: false,

        },
        {
          question: "แขกในงานจะได้รับรูปปริ้นหรือไม่",
          answer: "ทางแขกในงานจะสามารถดาวน์โหลดรูปคำอวยพร (wish image) ได้ทันทีในระบบอวยพร, แต่จะไม่มีรูปปริ้นออกมาเป็นกระดาษจริงภายในงาน",
          open: false,

        },
        {
          question: "PhotoWish แตกต่างจาก PhotoBooth อย่างไร",
          answer: "PhotoWish จะเป็นการเก็บบันทึกคำอวยพรสุดพิเศษจากแขกที่มาในงาน และจัดทำเป็นรูปเล่มคำอวยพรจัดส่งให้ในวันถัดไป, PhotoBooth จะเป็นการถ่ายรูป 3 ท่าทาง ให้กับแขกที่มาในงานที่บูธถ่ายรูป และจะได้รับรูปถ่ายเป็นที่ระลึก",
          open: false,
        },


        // Add more questions as needed
      ]
    }
  })


  // ---- computed to get current content by page -----
  const currentContent = computed(() => {
    let content: any = {}
    switch (page) {

     // ------ Realtime Slides -------
      case 'slide-home':
        content = contentSlideHome.value
        break;

      case 'slide-info':
        content = contentSlideInfo.value
        break;

      case 'slide-photos':
        content = contentSlidePhotos.value
        break;

      case 'slide-share':
        content = contentSlideShare.value
        break;

      case 'slide-howToUse':
        content = contentSlideHowToUse.value
        break;

      case 'slide-faqs':
        content = contentSlideFaqs.value
        break;
      
      default:
        break;
    }

    return content
  })

  return {
    content: currentContent, // The localized content
  }
}
